<template>
    <router-view v-if="rt" :params="params" :lang="this.lang" :locale="locale"
                 :currency_list="currency_list" :lang_list="lang_list"
                 @setLanguage="setLanguage" />
</template>

<script>
import config from '../js/config.json'
import l_ru from '../lang/ru.json'
import l_en from '../lang/en.json'

export default {
    data() {
        return {
            rt: false,
            locale: 'ru',
            server_admin: 'http://booking.isvsi.ru',
            params: {
                api_server_1c: '',
                id_hotel: 1,
                all: false
            },
            currency_list: {
                '036': {name: 'AUD', text: 'Австралийский доллар', code: '036', active: false},
                '040': {name: 'ATS', text: 'Австрийский шиллинг', code: '040', active: false},
                '056': {name: 'BEF', text: 'Бельгийский франк', code: '056', active: false},
                '826': {name: 'GBP', text: 'Британский фунт', code: '826', active: false},
                '124': {name: 'CAD', text: 'Канадский доллар', code: '124', active: false},
                '203': {name: 'CZK', text: 'Чешская крона', code: '203', active: false},
                '208': {name: 'DKK', text: 'Датская крона', code: '208', active: false},
                '528': {name: 'NLG', text: 'Нидерландский гульден', code: '528', active: false},
                '233': {name: 'EEK', text: 'Эстонская крона', code: '233', active: false},
                '978': {name: 'EUR', text: 'Единая европейская валюта', code: '978', active: false},
                '246': {name: 'FIM', text: 'Финская марка', code: '246', active: false},
                '250': {name: 'FRF', text: 'Французский франк', code: '250', active: false},
                '276': {name: 'DEM', text: 'Немецкая марка', code: '276', active: false},
                '300': {name: 'GRD', text: 'Греческая драхма', code: '300', active: false},
                '344': {name: 'HKD', text: 'Гонконгский доллар', code: '344', active: false},
                '348': {name: 'HUF', text: 'Венгерский форинт', code: '348', active: false},
                '372': {name: 'IEP', text: 'Ирландский фунт', code: '372', active: false},
                '380': {name: 'ITL', text: 'Итальянская лира', code: '380', active: false},
                '392': {name: 'JPY', text: 'Японская йена', code: '392', active: false},
                '428': {name: 'LVL', text: 'Латвийский лат', code: '428', active: false},
                '440': {name: 'LTL', text: 'Литовский лит', code: '440', active: false},
                '484': {name: 'MXN', text: 'Мексиканский песо', code: '484', active: false},
                '554': {name: 'NZD', text: 'Новозеландский доллар', code: '554', active: false},
                '578': {name: 'NOK', text: 'Норвежская крона', code: '578', active: false},
                '985': {name: 'PLN', text: 'Польский злотый', code: '985', active: false},
                '620': {name: 'РТЕ', text: 'Португальское эскудо', code: '620', active: false},
                '643': {name: 'RUB', text: 'Российский рубль', code: '643', active: true},
                '702': {name: 'SGD', text: 'Сингапурский доллар', code: '702', active: false},
                '703': {name: 'SKK', text: 'Словацкая крона', code: '703', active: false},
                '710': {name: 'ZAR', text: 'Южноафриканский ранд', code: '710', active: false},
                '724': {name: 'ESP', text: 'Испанская песета', code: '724', active: false},
                '752': {name: 'SEK', text: 'Шведская крона', code: '752', active: false},
                '756': {name: 'CHF', text: 'Швейцарский франк', code: '756', active: false},
                '980': {name: 'UAH', text: 'Украинская гривна', code: '980', active: false},
                '840': {name: 'USD', text: 'Американский доллар', code: '840', active: false}
            },
            lang_list: {
                'ru': {name: 'ru', text: 'Русский'},
                'en': {name: 'en', text: 'English'}
            },
            lang: null
        }
    },
    methods: {
        getParams(p, key = p) {
            let r = this.url_r.searchParams.get(p)
            if (r) {
                this.params[key] = r
            } else if (key === "currency") {
                this.params["currency"] = '643'
            }
        },
        getParamsLang() {
            /**  Выбор языка */
            let lang = this.url_r.searchParams.get("lang")
            switch (lang) {
                case 'en': this.lang = l_en; break;
                default: this.lang = l_ru; break;
            }
            this.rt = true
        },
        setLanguage(data) {
            switch (data.l) {
                case 'en': this.lang = l_en; break;
                default: this.lang = l_ru; break;
            }
            this.rt = true
        }
    },
    beforeMount() {
        this.url_string = window.location.href
        this.url_r = new URL(this.url_string)
        /**  Выбор языка */
        this.getParams("id", 'id_hotel');
        this.getParams("all");
        this.getParams("start");
        this.getParams("end");
        this.getParams("checkin");
        this.getParams("adults");
        this.getParams("childes");
        this.getParams("page_booking");
        this.getParams("form");
        this.getParams("lang");
        this.getParams("currency");
        this.getParamsLang();
    },
    mounted() {
        this.params.api_server_1c = config.ac
        this.params.app = config.au
    },
}
</script>


<style>
</style>
