import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'

/** Иконки */
import IconAccountMultiple from "./components/icons/icon-account-multiple.vue";
import IconAccount from "./components/icons/icon-account.vue";
import IconCalendarMonth from "./components/icons/icon-calendar-month.vue";
import IconChevronLeft from "./components/icons/icon-chevron-left.vue";
import IconChevronRight from "./components/icons/icon-chevron-right.vue";
import IconChevronBottom from "./components/icons/icon-chevron-bottom.vue";
// import IconChevronUp from "./components/icons/icon-chevron-up.vue";
import IconCloseThick from "./components/icons/icon-close-thick.vue";
import IconTrashCan from "./components/icons/icon-trash-can.vue";

/** Флаги */
import Flags from "./components/Flags.vue";

// import "@mdi/font/css/materialdesignicons.css";
import 'vuetify/styles';

import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
// import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
    components,
//     // directives,
})

import './assets/main.css'

const app = createApp(App)

app.component('icon-account-multiple', IconAccountMultiple)
app.component('icon-account', IconAccount)
app.component('icon-calendar-month', IconCalendarMonth)
app.component('icon-chevron-left', IconChevronLeft)
app.component('icon-chevron-right', IconChevronRight)
app.component('icon-chevron-bottom', IconChevronBottom)
// app.component('icon-chevron-up', IconChevronUp)
app.component('icon-close-thick', IconCloseThick)
app.component('icon-trash-can', IconTrashCan)

app.component('flags', Flags)

app.use(router)
app.use(vuetify);


app.mount('#app')
