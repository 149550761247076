<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="color" :width="size">
    <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
  </svg>
</template>

<script>
export default {
  props: ['size', 'color'],
  name: "icon-chevron-right",
}
</script>
