<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :fill="color" :width="size" :height="size">
      <path d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z"/>
  </svg>
</template>

<script>
export default {
  props: ['size', 'color'],
  name: "icon-chevron-bottom",
}
</script>
