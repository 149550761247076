import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/search/',
      name: 'search',
      component: () => import('../views/Search.vue'),
      props: (route) => ({ code: route.query.code })
    },
    {
      path: '/',
      name: 'base',
      component: () => import('../views/Base.vue'),
      props: (route) => ({ code: route.query.code })
    },
    {
      path: '/calendar/',
      name: 'calendar',
      component: () => import('../views/Calendar.vue'),
      props: (route) => ({ code: route.query.code })
    },
    {
      path: '/currency/',
      name: 'currency',
      component: () => import('../views/Currency.vue'),
      props: (route) => ({ code: route.query.code })
    },
    {
      path: '/lang/',
      name: 'lang',
      component: () => import('../views/Language.vue'),
      props: (route) => ({ code: route.query.code })
    },
    {
      path: '/guests/',
      name: 'guests',
      component: () => import('../views/Guests.vue'),
      props: (route) => ({ code: route.query.code })
    },
    {
      path: '/booking/',
      name: 'booking',
      component: () => import('../views/Booking.vue'),
      props: (route) => ({ code: route.query.code })
    }
  ]
})

export default router
